import Web3 from 'web3'
class WalletState {
	wallet = {
		chainId: null,
		account: null,
		lang: "Ch"
	}

	config = {
		//IDO合约
		IDO: "0xfda3529eEce65A449ED3BcD3992f77ba18822a07",
	}

	listeners = []

	constructor() {
		this.subcripeWeb3();
		this.getConfig();
	}
	//listen the wallet event
	async subcripeWeb3() {
		let page = this;
		if (window.ethereum) {
			window.ethereum.on('accountsChanged', function (accounts) {
				page.connetWallet();
				console.log('accountsChanged', accounts);
			});
			window.ethereum.on('chainChanged', function (chainId) {
				page.connetWallet();
				page.getConfig();
				console.log('chainChanged', chainId);
			});
		}
		// window.ethereum.on('connect', (connectInfo) => { });
		// window.ethereum.on('disconnect', (err) => { });
		// window.ethereum.isConnected();

		//         4001
		// The request was rejected by the user
		// -32602
		// The parameters were invalid
		// -32603
		// Internal error
	}

	async getConfig() {
		if (!Web3.givenProvider) {
			console.log("not wallet found");
		}

		var storage = window.localStorage;
		if (storage) {
			var lang = storage["language"];
			if (lang) {
				this.wallet.lang = lang;
			}
		}
		console.log("config", this.config);
		console.log("wallet", this.wallet);
		this.notifyAll();
	}

	async connetWallet() {
		let provider = Web3.givenProvider || window.ethereum;
		console.log("provider", provider);
		if (provider) {
			Web3.givenProvider = provider;
			const web3 = new Web3(provider);
			const chainId = await web3.eth.getChainId();
			console.log(chainId);
			this.wallet.chainId = chainId;
			const accounts = await web3.eth.requestAccounts();
			console.log('accounts');
			console.log(accounts);
			this.wallet.account = accounts[0];
			this.notifyAll();
		} else {
			setTimeout(() => {
				this.connetWallet();
			}, 3000);
		}
	}

	onStateChanged(cb) {
		this.listeners.push(cb);
		cb();
	}

	removeListener(cb) {
		this.listeners = this.listeners.filter(item => item !== cb);
	}

	notifyAll() {
		for (let i = 0; i < this.listeners.length; i++) {
			const cb = this.listeners[i];
			cb();
		}
	}

}
//export const CHAIN_ID = 97
//export const CHAIN_SYMBOL = "tBNB"
//export const CHAIN_ERROR_TIP = "请连接币安测试链钱包使用"
 export const CHAIN_ID = 56
 export const CHAIN_SYMBOL = "BNB"
 export const CHAIN_ERROR_TIP = "请连接币安链钱包使用"
export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
export const MAX_INT = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
export default new WalletState();
