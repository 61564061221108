<template>
  <div id="app">
    <BHeader />
    <router-view />
    <!-- <BFooter /> -->
  </div>
</template>

<script>
import BHeader from "@/components/BHeader.vue";
import BFooter from "@/components/BFooter.vue";
import WalletState from "./state/WalletState";
import { saveRef } from "./utils/utils";

export default {
  name: "App",
  components: {
    BHeader,
    BFooter,
  },
  mounted() {
    saveRef();
    WalletState.connetWallet();
  },
};
</script>

<style lang="less">
#app {
  height: auto;
}
@media screen and (min-width:800px) {
  #app {
    max-width: 450px;
    margin: 0 auto;
  }
}
</style>
