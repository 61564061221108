import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import 'amfe-flexible'

import "@/assets/styles/global.css"
import "@/assets/styles/mobile.less"

import VueClipBoard from 'vue-clipboard2'

Vue.use(ElementUI).use(VueClipBoard)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
