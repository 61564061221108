<template>
  <el-row class="scout-menu">
    <el-col :span="24" class="w-main scout-menu-main">
      <el-row
        type="flex"
        justify="space-between"
        align="center"
        style="height: inherit"
      >
        <el-col :span="12" class="header-left">
          <a href="/" class="logo-Burn">
            <h1></h1>
          </a>
          <el-menu
            :default-active="defaultActived"
            router
            mode="horizontal"
            background-color="#1b1b1b"
            text-color="#fff"
            active-text-color="#44c323"
            style="border: none;display: none;"
            class="menu-pc"
          >
            <el-menu-item index="/">
              <i class="el-icon-menu menu-icon"></i>
              <span slot="title">IDO铸造</span>
            </el-menu-item>
          </el-menu>
        </el-col>
        <el-col :span="12" class="header-right">
          <div class="header-btn">
            <el-button type="info" round class="btn"> BSC </el-button>
            <img src="@/assets/header_c.png" alt="c" class="btn-img" />
          </div>
          <img
            src="@/assets/header_a.png"
            alt="a"
            class="right-menu"
            @click="handleClickMenuShow"
          />
          <el-button
            type="success"
            size="small"
            class="right-connect joinPurse"
            :loading="connectLoading"
            @click="handleClickWallet"
            >{{ showAccount(account) || "连接钱包" }}
          </el-button>
        </el-col>
        <el-drawer :visible.sync="mobile" size="50%">
          <el-menu
            :default-active="defaultActived"
            router
            background-color="#1b1b1b"
            text-color="#fff"
            active-text-color="#44c323"
            style="border: none"
            @select="handleSelectMenu"
          >
            <el-menu-item index="/">
              <i class="el-icon-menu menu-icon"></i>
              <span slot="title">IDO铸造</span>
            </el-menu-item>
          </el-menu>
        </el-drawer>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
import WalletState from "../state/WalletState";
import { showAccount } from "../utils/utils";
export default {
  name: "BHeader",
  data() {
    return {
      defaultActived: "/",
      connectLoading: false,
      mobile: false,
      account: "",
      showSheet: false,
      connectLoading: false,
      actions: [{ name: "中文" }, { name: "English" }],
    };
  },
  mounted() {
    let url = window.location.href.split("?")[0];
    let paths = url.split("/");
    let localPath = "/" + paths[paths.length - 1];
    this.defaultActived = localPath ? localPath : "/";
    WalletState.onStateChanged(this.handleAccountsChanged);
  },
  methods: {
    // 判断是pc和是移动
    isMobile() {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
    },
    // 点击connect按钮
    handleClickWallet() {
      if (this.account) {
        return;
      }
      this.connectLoading = true;
      WalletState.connetWallet();
    },
    handleAccountsChanged() {
      const wallet = WalletState.wallet;
      this.chainId = wallet.chainId;
      this.account = wallet.account;
      if (wallet.account) {
        this.connectLoading = false;
      }
    },
    showAccount,
    // 控制导航显示隐藏
    handleClickMenuShow() {
      this.mobile = true;
    },
    // 点击导航事件
    handleSelectMenu() {
      this.mobile = false;
    },
  },
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.joinPurse {
  background-color: transparent;
  border: 1px solid #fff;
}
.scout-menu {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  color: #fff;
  background-color: #000;

  .w-main {
    padding: 0 25px;
    height: 80px;
    width: 100%;
    margin: 0 auto;

    .logo-Burn {
      display: inline-block;
      width: 102px;
      height: 37px;
      background: url("@/assets/logo.png") no-repeat 0 0;
      background-size: contain;
    }

    .menu-icon {
      width: 16px;
      height: 16px;
      margin-right: 5px;
      color: #fff;
    }

    :deep(.el-menu-item) {
      border-bottom: none;
      padding: 0 12px;
    }

    :deep(.el-menu-item.is-active) {
      .menu-icon {
        color: #44c323;
      }
    }

    .header-left {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .header-right {
      display: flex;
      flex-direction: row;
      justify-content: end;
      align-items: center;

      .header-btn {
        position: relative;

        .btn {
          display: flex;
          align-items: center;
          width: 85px;
          height: 32px;
          padding-left: 36px;
          background-color: #434441;
          border-color: #434441;
        }

        .btn-img {
          position: absolute;
          left: 0;
          top: 0;
          width: 32px;
          height: 32px;
        }
      }

      .right-menu {
        display: none;
      }

      .right-connect {
        margin-left: 5px;
      }
    }
  }
}

// 移动端适配
@media only screen and (max-width: 768px) {
  body,
  html {
    .scout-menu {
      .w-main {
        padding: 0 0.5rem;
        :deep(.el-drawer) {
          background-color: rgb(27, 27, 27);
        }
        .menu-pc {
          display: none;
        }

        .header-right {
          .right-menu {
            display: block;
            width: 0.4rem;
            height: 0.4rem;
            margin: 0 10px;
          }
        }
      }
    }
  }
}
</style>
