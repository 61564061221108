<template>
  <div class="b-footer">
    <a href="#">
      <img src="@/assets/footer_a.svg" alt="a">
    </a>
    <a href="#">
      <img src="@/assets/footer_b.svg" alt="a">
    </a>
    <a href="#">
      <img src="@/assets/footer_c.svg" alt="a" style="filter: brightness(100);">
    </a>
  </div>
</template>

<script>
export default {
  name: 'BFooter'
}
</script>



<style scoped lang="less">
.b-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #1b1b1b;
  a {
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    img {
      width: 30px;
      height: 30px;
    }
  }
}
</style>